/* eslint-disable no-return-await */
import { observer } from 'mobx-react';
import { useContext, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';

import {
  AddressOverlayContainer,
  Button,
  ButtonTitle,
  bypassInvoice,
  CaseDocuments,
  Cell,
  ColumnLayout,
  Copyable,
  CopyableInfoItem,
  createAddressString,
  Divider,
  extractConfirmedAddressObject,
  getCaseDetailsForPartner,
  Icon,
  Image,
  InvoiceDocument,
  LightSubTitle,
  LoadingSpinner,
  MapContainer,
  MobileBackButton,
  Page,
  ProductType,
  Span,
  SubPage,
  SubTitle,
  Title,
  uploadInvoice,
  useCaseDetails,
  useLocation,
  UserInfo,
  ViewProvider,
  WarningMessage,
} from '@adac/core-view';

import {
  __,
  CaseStatusList,
  CaseWithAllRelationsDTO,
  CipheredToken,
  cipherSndOfferLink,
  config,
  Document,
  getClaimReference,
  getCompanyTypeWithContactManagerId,
  isCaseCancelled,
  isCaseClosedByBackoffice,
  isCaseCompleted,
  isCaseWithdrawnByHermes,
  isSignatureRefused,
} from '@adac/core-model';

import moment from 'moment';
import styled from 'styled-components';
import { useMyCompany } from '../../hooks/useMyCompany';
import StoreContext from '../../stores';

const HighlightedProductType = styled(ProductType)`
  & > .productType > p:nth-of-type(2) {
    background-color: #ffcc01;
  }
  gap: 200px;

  @media screen and (max-width: 600px) {
    gap: 20px;
  }
`;

const CopyableUserInfo = styled(UserInfo)`
  & > #name {
    grid-template-columns: 1fr 1fr 1fr;
    & > * {
      width: 100%;
    }
  }
`;

function CancelledWarning({
  caseDTO,
  isInvoiceUploadAllowed,
}: {
  caseDTO: CaseWithAllRelationsDTO | null;
  isInvoiceUploadAllowed: boolean;
}) {
  const warningMessage = (() => {
    if (isCaseClosedByBackoffice(caseDTO?.status.from)) {
      return __('Case close manually in OpenHome');
    }
    if (isCaseWithdrawnByHermes(caseDTO?.status.from)) {
      return __('Case has been withdrawn');
    }
    if (isCaseCancelled(caseDTO?.status.from)) {
      return __('Case has been cancelled');
    }
    if (isSignatureRefused(caseDTO?.status.from)) {
      return (
        <Copyable content={__('info.partnersupport.email')}>
          {__('signatureRefusedWarning')}
        </Copyable>
      );
    }
    return undefined;
  })();

  const invoiceMessage = __(
    isInvoiceUploadAllowed ? 'Please upload invoice' : 'Invoice upload disabled'
  );

  const shouldShowInvoiceMessage =
    isCaseWithdrawnByHermes(caseDTO?.status.from) ||
    isCaseClosedByBackoffice(caseDTO?.status.from) ||
    isCaseCompleted(caseDTO?.status.from);

  return (
    <WarningMessage>
      <>{warningMessage}</>
      <>{shouldShowInvoiceMessage && invoiceMessage}</>
    </WarningMessage>
  );
}

const CaseDetail = observer((props: RouteComponentProps<{ token: string }>) => {
  const { auth: authStore } = useContext(StoreContext);
  const [invoice, setInvoice] = useState<Document | null>(null);
  const { goTo } = useLocation();
  const myCompany = useMyCompany();

  const currentCompanyId = myCompany?.id;
  const token = props.match.params.token as CipheredToken;

  const {
    customerCoords,
    caseDetails: caseDTO,
    isLoading,
    confirmedAddress,
  } = useCaseDetails(
    [token, authStore.token, invoice],
    getCaseDetailsForPartner
  );

  const driverInfo = caseDTO?.driverInfo;
  const caseData = caseDTO?.case;

  const customerFullAddressString =
    (caseData && createAddressString(caseData)) || '';
  const finalConfirmedAddress = extractConfirmedAddressObject(confirmedAddress);
  const finalConfirmedAddressString = finalConfirmedAddress
    ? createAddressString(finalConfirmedAddress)
    : customerFullAddressString;

  const isCompleted = caseDTO?.status.event === CaseStatusList.CASE_COMPLETED;

  const driverHasFinished =
    caseDTO?.status.event === CaseStatusList.OPENING_COMPLETED;

  const isInvoiceUploadAllowed =
    myCompany &&
    myCompany.id === caseDTO?.companyInfo?.id &&
    config.invoiceModule.isInvoiceUploadAllowed(
      {
        event: `${caseDTO?.status?.event}`,
        data: caseDTO?.status.data,
        case: caseDTO?.case,
      },
      myCompany.type
    );

  const onUpload = async (file: File) => {
    const result = await uploadInvoice(token, file);
    setInvoice(result);
  };
  const onBypass = async () => {
    const result = await bypassInvoice(token);
    setInvoice(result);
  };

  if (!token || isLoading) {
    return (
      <Page>
        <MobileBackButton />
        <LoadingSpinner>
          {__('Loading case detail for your company for')} {token}
        </LoadingSpinner>
      </Page>
    );
  }

  const commissioningReference = caseData?.commissioningReference;
  const formattedCommissioningReference = commissioningReference
    ? `${getClaimReference(commissioningReference)}/${getCompanyTypeWithContactManagerId(commissioningReference)}`
    : '';

  return (
    <Page>
      <AddressOverlayContainer>
        <MobileBackButton />
        <SubPage>
          <Title>{customerFullAddressString}</Title>
          <Copyable
            style={{ width: 'fit-content' }}
            content={formattedCommissioningReference}
          >
            <LightSubTitle>
              {__('Hermes Id')}: {formattedCommissioningReference}
            </LightSubTitle>
          </Copyable>
        </SubPage>

        <MapContainer
          style={{ height: '160px' }}
          customerPosition={customerCoords}
        />

        <SubPage>
          {isCompleted || driverInfo ? (
            <>
              <SubTitle>{__('Beauftragter Mitarbeiter')}</SubTitle>

              <Cell
                isOpen={false}
                title={
                  <SubTitle>
                    {driverInfo
                      ? `${driverInfo.driver?.firstName} ${driverInfo.driver?.familyName}`
                      : __('No driver information')}
                  </SubTitle>
                }
                description=''
                onClick={() =>
                  driverInfo
                    ? goTo(`/driver/edit/${driverInfo.driver?.id}`, {
                        prefixed: false,
                      })
                    : undefined
                }
                orderView={() => (
                  <Image
                    style={{ width: 24, marginRight: 18 }}
                    source={driverInfo ? driverInfo.driver?.photo : null}
                  />
                )}
                rightView={<Icon name='arrow-right' />}
              />

              <Divider margin='24px' fullWidth />
            </>
          ) : (
            <ColumnLayout margin='10px' ratio='1fr'>
              <Link
                to={{
                  pathname: cipherSndOfferLink(currentCompanyId ?? null, token),
                }}
                target='_blank'
                rel='noopener noreferrer'
              >
                <Button type='submit' cta>
                  <ButtonTitle>{__('Dispatch a Driver')}</ButtonTitle>
                </Button>
              </Link>
            </ColumnLayout>
          )}

          <Span>
            <Span bold>{__('Appointment')}: </Span>
            {caseData?.scheduledTs ? (
              <>
                <Span>
                  {moment(caseData?.scheduledTs).format('DD.MM.YY')} um{' '}
                </Span>
                <Span>
                  {moment(caseData?.scheduledTs).format('HH.mm')} Uhr{' '}
                </Span>
              </>
            ) : (
              '-'
            )}
          </Span>
          <Divider margin='24px' fullWidth />

          <CopyableUserInfo
            title={caseData?.title || ''}
            firstName={caseData?.customerFirstName || ''}
            lastName={caseData?.customerFamilyName || ''}
            phone={caseData?.customerPhone}
            address={finalConfirmedAddressString}
            Item={CopyableInfoItem}
          />

          <Copyable content={caseData?.productNumber || ''}>
            <HighlightedProductType
              productType={caseData?.productType}
              productNumber={caseData?.productNumber}
            />
          </Copyable>

          {isCompleted && !caseData?.invoiceDocument && (
            <CancelledWarning
              caseDTO={caseDTO}
              isInvoiceUploadAllowed={!!isInvoiceUploadAllowed}
            />
          )}

          {/* TODO: for PBI 110735 Implement case event history into snd partner admin */}
          {/* <CaseStatusEventHistory
            isLoading={isLoading}
            history={{ push: goTo }}
            items={caseDetails?.logs}
          /> */}

          {isCompleted && (
            <>
              <Divider margin='24px' fullWidth />
              <CaseDocuments
                token={token}
                commissionReport={caseData?.commissionReport}
                commissionExtraDamage={caseData?.commissionExtraDamage}
                finalStatusReport={caseData?.finalStatusReport?.partner}
              >
                <InvoiceDocument
                  token={token}
                  // caseData?.invoiceDocument is not trusted ATM, as not always present value
                  invoiceDocument={caseData?.invoiceDocument}
                  onUpload={(isInvoiceUploadAllowed && onUpload) || undefined}
                  onBypass={(isInvoiceUploadAllowed && onBypass) || undefined}
                  accept='application/pdf'
                />
              </CaseDocuments>
            </>
          )}
          {driverHasFinished && (
            <>
              <Divider margin='24px' fullWidth />
              <WarningMessage>
                {__('Evidence PDF files are being generated, please wait...')}
              </WarningMessage>
            </>
          )}
        </SubPage>
      </AddressOverlayContainer>
    </Page>
  );
});

export default observer((props: RouteComponentProps<{ token: string }>) => (
  <ViewProvider>
    <CaseDetail {...props} />
  </ViewProvider>
));

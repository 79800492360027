import { __ } from '@adac/core-model';
import { Icon, Text, themeConfig, View } from '@adac/core-view';
import moment from 'moment';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  CommissioningReferenceFilter,
  DateFilter,
  InvoiceFilter,
  StreetAndHouseNumberFilter,
  ZipAndCityFilter,
} from './CaseTableFilters';
import { CaseTableCellItem } from './composite/caseListComponents';

const TableOverflow = styled.div`
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 12px;
`;

const TableHead = styled.thead`
  background-color: ${themeConfig.colors.backgroundLight};
`;

const TableBody = styled.tbody`
  background-color: ${themeConfig.colors.background};
`;

const TableRow = styled.tr`
  border-bottom: 1px solid #ddd;
  cursor: pointer;

  &:hover {
    background-color: ${themeConfig.colors.backgroundLight};
  }
`;

const TableHeader = styled.th`
  padding: 10px;
  text-align: left;
  font-weight: bold;
  font-size: 14px;

  & > input {
    display: flex;
  }
`;

const TableCell = styled.td`
  text-align: left;
  font-size: 14px;
`;

const PaddedLink = styled(Link)`
  width: 100%;
  height: 60px;
  padding: 16px;
  display: flex;
  align-items: center;
`;

const TableCellLink = ({
  children,
  link,
}: {
  children: ReactNode;
  link: string;
}) => (
  <TableCell>
    <PaddedLink to={link}>{children}</PaddedLink>
  </TableCell>
);

const InvoiceStyles = styled(View)`
  display: flex;
  flex-direction: row;
`;

const InvoiceText = styled(Text)`
  margin-left: 6px;
  font-size: 14px;
`;

interface CaseTableProps {
  items: CaseTableCellItem[];
  setFilterParams: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  showInvoice: boolean;
}

export const CaseTable = ({
  items,
  showInvoice,
  setFilterParams,
}: CaseTableProps) => {
  const handleFilterChange = (key: string, value: string) => {
    setFilterParams((prev) => {
      const newFilters = { ...prev };

      if (value) {
        newFilters[key] = value;
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [key]: _, ...rest } = newFilters;
        return rest;
      }

      return newFilters;
    });
  };

  const renderInvoiceInfo = ({
    cancelled,
    invoiceDocument,
  }: CaseTableCellItem) => {
    const hasInvoiceText = invoiceDocument ? __('yes') : __('no');

    if (invoiceDocument) {
      return (
        <InvoiceStyles>
          <Icon name='invoice' color={themeConfig.colors.ready} />
          <InvoiceText>{`${hasInvoiceText[0].toUpperCase()}${hasInvoiceText.slice(1)}`}</InvoiceText>
        </InvoiceStyles>
      );
    }

    if (cancelled !== 'no') {
      return (
        <InvoiceStyles>
          <Icon name='invoice' color={themeConfig.colors.gray} />
          <InvoiceText>{__(`cancelled:${cancelled}`)}</InvoiceText>
        </InvoiceStyles>
      );
    }

    return (
      <InvoiceStyles>
        <Icon name='invoice' color={themeConfig.colors.warning} />
        <InvoiceText>{`${hasInvoiceText[0].toUpperCase()}${hasInvoiceText.slice(1)}`}</InvoiceText>
      </InvoiceStyles>
    );
  };

  return (
    <TableOverflow>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>{__('Case search')}:</TableHeader>
            <TableHeader>{__('Period of Order')}:</TableHeader>
            <TableHeader>{__('Street')}:</TableHeader>
            <TableHeader>{__('Customer City')}:</TableHeader>
            <TableHeader>{__('Appointment')}:</TableHeader>
            {showInvoice && (
              <TableHeader>{__('Invoice uploaded')}?</TableHeader>
            )}
          </TableRow>
          <TableRow>
            <TableHeader>
              <CommissioningReferenceFilter
                handleFilterChange={handleFilterChange}
              />
            </TableHeader>
            <TableHeader>
              <DateFilter
                handleFilterChange={handleFilterChange}
                fromKey='createdAtFrom'
                toKey='createdAtTo'
              />
            </TableHeader>
            <TableHeader>
              <StreetAndHouseNumberFilter
                handleFilterChange={handleFilterChange}
              />
            </TableHeader>
            <TableHeader>
              <ZipAndCityFilter handleFilterChange={handleFilterChange} />
            </TableHeader>
            <TableHeader>
              <DateFilter
                handleFilterChange={handleFilterChange}
                fromKey='scheduledTsFrom'
                toKey='scheduledTsTo'
              />
            </TableHeader>
            {showInvoice && (
              <TableHeader>
                <InvoiceFilter handleFilterChange={handleFilterChange} />
              </TableHeader>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((cell) => (
            <TableRow>
              <TableCellLink link={cell.link}>
                {cell.commissioningReference}
              </TableCellLink>
              <TableCellLink link={cell.link}>
                {moment(new Date(cell.timeOfOrder)).format('DD/MM/YYYY')}
              </TableCellLink>
              <TableCellLink link={cell.link}>{cell.street}</TableCellLink>
              <TableCellLink link={cell.link}>{cell.place}</TableCellLink>
              <TableCellLink link={cell.link}>
                {cell.scheduledTs
                  ? `${moment(new Date(cell.scheduledTs)).format('DD/MM/YY')} ${__('at')} ${moment(new Date(cell.scheduledTs)).format('HH:mm')} ${__('hour')}`
                  : '-'}
              </TableCellLink>
              {showInvoice && (
                <TableCellLink link={cell.link}>
                  {renderInvoiceInfo(cell)}
                </TableCellLink>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableOverflow>
  );
};
